import React from 'react'
import Head from 'next/head'
import Navbar from '../../components/navbar'
import Footer from '../../components/footer'


export default function Custom404() {
    return (
        <>
            <Head>
            <title>404 - Page Not Found</title>
            </Head>
            <Navbar />
            <div className="container mx-auto px-4 py-8">
            <h1 className="text-4xl font-bold text-center">404 - Page Not Found</h1>
            <p className="text-lg text-center">The page you are looking for does not exist.</p>
            </div>
            <Footer />
        </>
    )
  }